@charset "utf-8";

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-text-center {
  text-align: center;
}
.is-text-right {
  text-align: right;
}
.is-text-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.inner-wrap-sm {
  max-width: $base-width-in;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
/*共通レイアウト*/
.mainWrap {
  position: relative;
  padding: 0 1%;
}
.layout--2col {
  max-width: $base-width;
  margin: 0 auto;
  padding: 0 0 90px;
  display: flex;
  align-items: flex-start;
  &__cont {
    width: calc(100% - (306px + 40px));
    @include mq-tab {
      width: calc(100% - (306px + vw(40)));
    }
  }
  &.cont--r {
    flex-direction: row-reverse;
    .layout--2col__cont {
      margin-left: 40px;
      @include mq-tab {
        margin-left: vw(40);
      }
    }
  }
  &.cont--l {
    .layout--2col__cont {
      margin-right: 40px;
      @include mq-tab {
        margin-right: vw(40);
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .layout--2col {
    padding: 7px 0 0;
    display: block;
    &__cont {
      width: 100%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: $display-width-s){
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: inline-block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
  /*共通レイアウト*/
  .mainWrap {
    padding: 0;
  }
  .home {
    .mainWrap {
      padding: 0 per(15);
    }
  }
}