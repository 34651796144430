@charset "utf-8";

//変数の定義
$base-width: 1286px;
$base-width-in: 940px;
$display-width-s: 767px;
$design-width: 1366;
$sp-width: 390;
$base-color: #fff;
$bg-color: #FBFBFB;
$font-color-base: #333333;
$color-theme: #36639A;
$color-theme-light:#ECF5FF;
$color-red: #C62828;
$color-red-dark: #7E091F;
$color-red-light: #FBF2F2;
$color-blue: #f0f8fe;
$color-blue-dark: #1255FF;
$color-pink: #ee063c;
$color-pink-dark: #b0022b;
$color-yellow: #FFE043;
$color-gold: #AB9B4E;
$color-gray: #FBFBFB;

@mixin mq-sp {
    @media screen and (min-width: 0) and (max-width: $display-width-s) {
        @content;
    }
}
@mixin mq-tab {
    @media screen and (min-width: $display-width-s) and (max-width: #{$design-width}px) {
        @content;
    }
}
@mixin mq-desktop {
    @media screen and (min-width: $display-width-s) {
        @content;
    }
}

//カテゴリー設定定義
$color-implant: #FBFBFB;
$color-ceramic: #EFCF5C;
$color-whitening: #7DCAD4;
$color-correction: #85C485;
$color-dentition: #BD9BD2;
$color-denture: #9CB6D1;
$color-tooth-decay: #D1AA9A;
$color-dental-treatment: #FCA477;
$size-implant: auto 75%;
$size-ceramic: auto 75%;
$size-whitening: auto 75%;
$size-correction: 75% auto;
$size-dentition: 75% auto;
$size-denture: 75% auto;
$size-tooth-decay: auto 75%;
$size-dental-treatment: auto 75%;

//フォントの定義
%font {
  font-size: 1.6rem;
  font-weight: 400;
  // font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  font-family: "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  color: $font-color-base;
  line-height: 1.625;
  @include mq-sp{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.57142;
  } //::スマホ
}

@mixin font-roboto {
  font-family: 'Roboto', sans-serif;
}

@mixin font-min {
  font-family: 'Noto Serif JP', serif;
}

@mixin font-os {
  font-family: 'Open Sans', sans-serif;
}

//タイトルの定義
@mixin ttl-base($size,$color:$font-color-base,$lineHeight:1.25,$weight:bold) {
  font-size: $size;
  color: $color;
  line-height: $lineHeight;
  font-weight: $weight;
  font-family: 'Roboto', "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
}

//vw計算
@function vw($pixelsVw,$width:$design-width) {
  @return calc($pixelsVw / $width) * 100vw;
}
//%計算
@function per($pixelsPer) {
  @return calc($pixelsPer / 390) * 100%;
}

//余白の定義
%margin-wrap {
  @include mq-sp{//::スマホ
    margin: 0 8px;
  }
}
%margin-wrap-in {
  margin: 0 24px;
  @include mq-sp{//::スマホ
    margin: 0 12px;
  }
}